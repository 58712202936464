import React, { useEffect, useState } from 'react';
import AlbumCover from './AlbumCover.tsx';
import AccordianRowContainer from './AccordianRowContainer.tsx';

export default function CoverFlow({label, releases, initClosed, setPlaylistId}) {
    const [isOpen, setIsOpen] = useState(initClosed ? false : true);
    const [visibleReleases, setVisibleReleases] = useState(releases);
    useEffect(() => {
      if (isOpen) {
        setVisibleReleases(releases);
      } else {
        const removeReleases = (releases) => {
          if (releases.length > 0) {
            setTimeout(() => {
              setVisibleReleases(releases.slice(1));
              removeReleases(releases.slice(1));
            }, 40);
          }
        };
        removeReleases(visibleReleases);
      }
    }, [isOpen, visibleReleases, releases]);
  return (
    <AccordianRowContainer label={label} isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className={`album-covers ${isOpen ? 'fadeIn' : 'fadeOut'}`}>
      {visibleReleases.map((release, index) => (
          <AlbumCover
            key={release.album}
            {...release}
            setPlaylistId={setPlaylistId}
          />
        ))}
      </div>
    </AccordianRowContainer>
  )
};
