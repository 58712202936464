export default function SDCard({isPressingSD}) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="135.729 300 64.271 77.971" 
      width={isPressingSD ? 18 : 20} 
      height={isPressingSD ? 18 : 20}>
        <path
          fill="#FFF"
          stroke="#000"
          strokeMiterlimit="3.06"
          strokeWidth="0"
          d="M147.386 300l-11.657 11.312v66.659h61.76l2.511-2.437V300h-52.614"
        ></path>
        <g fill="#0d0d0d" stroke="#000" strokeWidth="0">
          <path d="M195.535 304.183h-6.04v13.708h6.104l-.064-13.708zM185.696 304.183h-6.04v13.708h6.104l-.064-13.708zM165.421 304.183h-6.04v13.708h6.104l-.064-13.708zM175.503 304.183h-6.04v13.708h6.104l-.064-13.708zM155.622 304.183h-6.04v13.708h6.104l-.064-13.708zM145.43 307.566l-6.04 5.677v4.648h6.104l-.064-10.325z"></path>
        </g>
        <g transform="matrix(.0411 0 0 .0411 146.34 390.3)">
          <g
            fill="#0d0d0d"
            fillRule="nonzero"
            stroke="none"
            transform="translate(-30.907 -1015.742)"
          >
            <path d="M203.258 425.351c0 12.58-18.954 13.613-73.234 12.58-84.434-1.38-155.256-9.823-155.256-9.823l-33.136 91.327s80.385 5.515 166.164 5.515c122.343 0 223.148 4.824 241.586-75.992 13.096-57.036-36.704-83.917-95.98-101.493-39.977-11.89-112.867-28.26-112.867-42.562 0-18.266 48.938-17.749 82.884-18.783 37.048-1.033 180.587-1.206 180.587-1.206l18.265-51.694s-103.217-2.757-205.744 2.24c-147.675 7.41-175.245 40.15-178.691 71.51-4.48 40.667 50.316 68.927 83.572 81.678 31.706 12.062 81.85 21.023 81.85 36.703"></path>
            <path d="M597.516 328.51c-.69-1.379-1.551-2.757-2.413-4.136l178.002-44.801c28.26 13.785 51.867 31.533 64.101 54.279 11.029 19.988 8.788 40.666-3.963 60.827-101.838 126.48-366.687 125.618-462.322 129.926l41.528-117.002c25.33-.862 61.861-4.48 83.745-8.788 21.712-4.308 41.184-9.995 57.037-16.715 15.853-6.72 28.26-14.302 36.186-22.401 7.754-8.099 11.545-16.37 9.994-24.986-.344-2.067-1.034-4.135-1.895-6.203"></path>
            <path d="M735.368 264.064c-10.339-4.308-24.641-9.65-35.152-12.407l-130.27 52.557c3.79 1.895 9.477 6.203 12.406 8.098.862.69 1.55 1.207 2.412 1.724l150.604-49.972"></path>
            <path d="M628.533 239.595c-21.54-3.101-39.46-4.48-49.972-4.825-41.528-1.033-57.898-1.895-104.078-1.723l-18.438 52.04c11.373.344 16.542-.173 35.67 1.723 17.575 1.723 33.773 4.308 48.075 8.098l88.743-55.313"></path>
            <path d="M793.266 486.523h-12.407l-5.342 38.599h-6.203l5.514-38.599h-12.406l.861-5.514h30.845l-.862 5.514"></path>
            <path d="M838.93 525.122h-5.86v-33.43l-17.748 33.43-7.582-33.774h-.172l-10.856 33.774h-5.858l14.646-44.113h4.998l6.72 29.81 15.853-29.81h5.859v44.113"></path>
          </g>
        </g>
      </svg>
    );
  }