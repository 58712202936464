import React from "react";
import CoverFlow from "./CoverFlow.tsx";
import mirage from './media/artwork/mirage_400.jpg';
import fatima from './media/artwork/fatima_400.jpg';
import mofatt from './media/artwork/mofatt_400.jpg';
import htdw from './media/artwork/htdw_400.jpg';
import halfbaby from './media/artwork/halfbaby_400.jpg';
import tkay from './media/artwork/tkay_400.jpg';
import youngstar from './media/artwork/youngstar_400.jpg';
import advent from './media/artwork/advent_400.jpg';

const releases = [
  {cover: tkay, artist: 'Tkay Maidza feat. Killer Mike', album: 'Carry On (Remixes)', label: 'Downtown/Interscope', notes: 'Carry On (Dubbel Dutch Remix)', year: 2016},
  {cover: htdw, artist: 'How To Dress Well', album: 'What Is This Heart? (Remixes)', label: 'Weird World', notes: 'Very Best Friend (Dubbel Dutch Remix)', year: 2014},
  {cover: advent, artist: 'Various', album: 'Advent 14 / 15', label: 'LuckyMe', notes: 'Dougie F Ft. DJ Fire - Jasmine (Dubbel Dutch Rmx)', year: 2014},
  {cover: youngstar, artist: 'Youngstar', album: 'Dubbel Dutch Bongo Remix', label: 'DDJ\'s Productions', year: 2014},
  {cover: fatima, artist: 'Fatima Al Qadiri', album: 'GSX Remixes', label: 'UNO', notes: 'Vatican Vibes (Dubbel Dutch Remix)', year: 2012},
  {cover: mirage, artist: 'Nguzunguzu', album: 'Mirage', label: 'Time No Place', notes: 'Strut (Dubbel Dutch Remix)', year: 2012},
  {cover: mofatt, artist: 'Ariane Moffatt', album: 'MA Remix', label: 'Mo\'Fat', notes: 'Hôtel Amour (Dubbel Dutch Remix)', year: 2012},
  {cover: halfbaby, artist: 'Vybz Kartel', album: 'Half On A Baby (Remixes)', label: 'Mixpak', notes: 'Half On A Baby (Dubbel Dutch Remix)', year: 2012},
];

export default function Works(props) {
  return <CoverFlow {...props} label="remixes" releases={releases} />
}