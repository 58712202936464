import React from "react";

function Close() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="100 100 100 100" width="30" height="30">
      <path
        fill="#F95656"
        stroke="#FFF"
        strokeWidth="8"
        d="M180.537 108.605l9.421 9.421-30.644 30.644 30.645 30.646-9.421 9.42-30.645-30.645-30.646 30.645-9.42-9.42 30.645-30.646-30.645-30.644 9.421-9.421 30.645 30.645 30.644-30.645z"
      ></path>
    </svg>
  );
}

export default Close;
