import React from "react";

function DownArrow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="100 131.622 100 68.379"
      width="10"
      height="10"
    >
      <path
        fill="#FFF"
        stroke="#FFF"
        strokeWidth="0"
        d="M150 200.001l50-68.379H100l50 68.379z"
        bxshape="triangle 100 -200.001 100 68.379 0.5 0 1@a5ca3fa1"
      ></path>
    </svg>
  );
}

export default DownArrow;
