import DownArrow from './DownArrow.tsx';
import RightArrow from './RightArrow.tsx';

export default function AccordianRowContainer({label, children, isOpen, setIsOpen, collapseContent = false}) {
  return (
    <div className="accordian-row-container">
      <div className="accordian-row" onClick={() => setIsOpen(!isOpen)}>
        <h2 className="section">{label}</h2>
        <div className="arrow-icon">
          {isOpen ? <DownArrow /> : <RightArrow />}
        </div>
      </div>
      {collapseContent ? (isOpen && children) : children}
    </div>
  );
}
