import {useEffect, useRef} from 'react';
import Close from './Close.tsx';

export default function YoutubePlayer({playlistId, setPlaylistId}) {
    const player = useRef(null);

    useEffect(() => {
        if (!window.YT) {
            const tag = document.createElement('script');
            tag.src = "https://www.youtube.com/iframe_api";
            
            const firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

            window.onYouTubeIframeAPIReady = function () {
                if (!playlistId) {
                    return;
                }

                createPlayer();
            }
        }
    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!playlistId) {
            return;
        }
        if (!window.YT) {
            return;
        }
        createPlayer();
    // eslint-disable-next-line
    }, [playlistId]);

    const createPlayer = () => {
        if (player.current) {
            player.current.destroy();
        }
        player.current = new window.YT.Player('player', {
            height: '250',
            width: '100%',
            playerVars: {
                'playsinline': 1,
                'list': playlistId,
                'listType': 'playlist'
            },
            events: {
                'onReady': (event) => event.target.playVideo(),
                'onStateChange': () => {}
            }
        });
    }

    return (
        <div className={`youtube-container ${Boolean(playlistId) ? '' : 'hidden'}`}>
            <div className="youtube-close" onClick={() => {
                player.current.stopVideo();
                setPlaylistId(null);
            }}>
                <Close />
            </div>
            <div id="player" />
        </div>
    );
}
