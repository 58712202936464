import React from 'react';
import CoverFlow from './CoverFlow.tsx';
import wwcf from './media/artwork/WWCF_400.jpg';
import kelela from './media/artwork/kelela_400.jpg';
import le1f from './media/artwork/le1f_400.jpg';
import cadence from './media/artwork/cadence_weapon_400.jpg';
import badgyal from './media/artwork/badgyal_400.jpg';
import fetty from './media/artwork/fetty_400.jpg';

const releases = [
    {cover: badgyal, artist: 'Bad Gyal', album: 'Worldwide Angel', year: 2018, label: 'Puro Records', playlistId: 'PLnD5HfA5-m76z7LhOen_DIM4p2sBdibkZ'},
    {cover: cadence, artist: 'Cadence Weapon', album: 'Cadence Weapon', year: 2018, label: 'eOne', playlistId: 'PLnD5HfA5-m758cVtLgXlc5kgZVwcuz-5a'},
    {cover: kelela, artist: 'Kelela', album: 'Take Me Apart', year: 2017, label: 'Warp', playlistId: 'PLnD5HfA5-m77kKB2N2grmZrcVIj4VAUcc'},
    {cover: le1f, artist: 'Kalifa', album: 'Riot Boi', label: 'Terrible Records', year: 2015, playlistId: 'PLnD5HfA5-m76aOZF1KyAPwMOQRZi0Gh8P'},
    {cover: fetty, artist: 'Bauuer', album: 'Promises ft Fetty Wap & Dubbel Dutch', year: 2015, playlistId: 'PLnD5HfA5-m74ccSEh-g4DlII5ApMB1BZn'},
    {cover: wwcf, artist: 'Popcaan', album: 'Where We Come From', year: 2014, label: 'Mixpak', playlistId: 'PLnD5HfA5-m75-Kc-J8a4-_X3vy-LN4ZFh'},
  ];

export default function Credits(props) {
    return <CoverFlow {...props} label="production credits" releases={releases} />
}
