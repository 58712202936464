import { useEffect, useState } from 'react';
import cosmicTonePoemsCover from './media/artwork/cosmictonepoems.jpg';
export default function CosmicTonePoems() {
    const [loading, setLoading] = useState(true);
    const [promoCode, setPromoCode] = useState(null);
    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + 'getCode', {
            method: 'POST',
            body: JSON.stringify({releaseName: 'cosmictonepoems'}),
            headers: {
                'Content-Type': 'application/json'
            },
        }).then((res) => {
            if (!res.ok) {
                setLoading(false);
                return;
            }
            return res.json();
        }).then((value) => {
            if (value.promoCode) {
                setPromoCode(value.promoCode);
            } else {
                setPromoCode('');
            }
            setLoading(false);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div className="main-container">
            <div
                className="promo-cover-container"
            >
                <div
                    className="large-cover"
                    style={{ backgroundImage: `url(${cosmicTonePoemsCover})` }}
                />
                {loading && 'Looking for download code...'}
                {promoCode 
                    ? <a 
                        style={{fontSize: 24, padding: 10}} 
                        href={`https://dubbeldutch.bandcamp.com/yum?code=${promoCode}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >Redeem code on Bandcamp</a> 
                    : 'Sorry, no more codes :('
                }
            </div>
        </div>
    );
}
