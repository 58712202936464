import React, { useState } from 'react';
import RightArrow from './RightArrow.tsx';

interface AlbumCoverProps {
  cover: string;
  releaseName: string;
  releaseDate: string;
}

const AlbumCover: React.FC<AlbumCoverProps> = ({ cover, album, label, year, notes = '', artist = '', playlistId = '', setPlaylistId}) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div
      className="album-cover"
      style={{ backgroundImage: `url(${cover})` }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isHovered && (
        <div className="release-info">
          {Boolean(artist.length) && (
              <p><strong>Artist:</strong> {artist}</p>
          )}
          <p><strong>Album:</strong> {album}</p>
          <p><strong>Label:</strong> {label}</p>
          <p><strong>Year:</strong> {year}</p>
          {Boolean(notes.length) && (
              <p><strong>Notes:</strong> {notes}</p>
          )}
          {playlistId && (
            <div className="play-button" onClick={() => setPlaylistId(playlistId)}>
              <RightArrow width="60" height="60" />
            </div>
          )}
          
        </div>
      )}
    </div>
  );
};

export default AlbumCover;