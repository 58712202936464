import React from 'react';

import Works from './Works.tsx';
import Credits from './Credits.tsx';
import Remixes from './Remixes.tsx';
import Mixtapes from './Mixtapes.tsx';

export default function CollectedWorks({setPlaylistId}) {
    return (
        <div>
            <Works setPlaylistId={setPlaylistId} />
            <Mixtapes setPlaylistId={setPlaylistId} />
            <Credits setPlaylistId={setPlaylistId} />
            <Remixes setPlaylistId={setPlaylistId} />
        </div>
    );
}