import React from "react";

export default function RightArrow({width = "10", height = "10"}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="315.813 315.814 68.379 100"
      width={width}
      height={height}
    >
      <path
        fill="#FFF"
        stroke="#FFF"
        strokeWidth="0"
        d="M384.192 365.814l-68.38-50v100l68.38-50z"
        bxshape="triangle -100 200.001 100 68.379 0.5 0 1@a530915c"
      ></path>
    </svg>
  );
}
