export default function SDCardEject({isPressingSD}) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="228.548 300 64.271 77.971"
        width={isPressingSD ? 18 : 20} 
        height={isPressingSD ? 18 : 20}
      >
        <path
          fill="#FFF"
          stroke="#000"
          strokeMiterlimit="3.06"
          strokeWidth="0"
          d="M240.205 300l-11.657 11.312v66.659h61.76l2.511-2.437V300h-52.614"
        ></path>
        <g transform="matrix(.0411 0 0 .0411 239.16 382.3)">
          <g fill="#0d0d0d" stroke="#000" transform="translate(-30.907 -1015.742)">
            <path
              d="M560.857-381.83l502.059 361.985H58.797l502.06-361.985z"
              bxshape="triangle 58.797 -381.83 1004.119 361.985 0.5 0 1@a42bad5a"
            ></path>
            <path d="M64.984 72.697H1058.545V223.681H64.984z"></path>
          </g>
        </g>
      </svg>
    );
  }