import React from 'react';
import CoverFlow from './CoverFlow.tsx';

import trollsta from './media/artwork/trollsta_400.jpg';
import throwback from './media/artwork/throwback_400.jpg';
import pulso from './media/artwork/pulso_400.jpg';
import duttyArtzWhiteLabel from './media/artwork/dutty-artz-white_400.jpg';
import unknownToTheUnknown from './media/artwork/UTTU_400.jpg';
import cloudClub from './media/artwork/cloud_club_400.jpg';
import hymn from './media/artwork/hymn_400.jpg';
import selfHelpRiddims from './media/artwork/self_help_riddims_400.jpg';
import ripTribute from './media/artwork/rip_tribute_400.jpg';
import lastBreath from './media/artwork/last_breath_400.jpg';
import eskiTech from './media/artwork/soulja-man_400.jpg';
import passiflora from './media/artwork/passiflora_single_art_400.png';
import cosmictonepoems from './media/artwork/cosmictonepoems.jpg';

const releases = [
    {cover: cosmictonepoems, album: 'Cosmic Tone Poems', label: 'Rare Earth Tones', year: 2024, playlistId: 'PLnD5HfA5-m77cswEokzpkH_2wtyU-FuyO'},
    {cover: passiflora, album: 'Passiflora', label: 'Rare Earth Tones', year: 2024, playlistId: 'PLnD5HfA5-m77cswEokzpkH_2wtyU-FuyO'},
    {cover: cloudClub, album: 'Cloud Club', label: 'Mixpak', year: 2013, playlistId: 'PLnD5HfA5-m76aaovuMLssjqqMBwSlRLHC'}, 
    {cover: selfHelpRiddims, album: 'Self Help Riddims', label: 'Mixpak', year: 2012, playlistId: 'PLnD5HfA5-m76myPS1i8aW9itNVxlRqIn_'}, 
    {cover: eskiTech, album: 'Eski-Tech', label: 'Body High', year: 2012, notes: 'As DJ Soulja-Man', playlistId: 'PLnD5HfA5-m77VbONf67zfALT28MvxNnY9'}, 
    {cover: ripTribute, album: 'RIP Tribute', label: 'Body High', year: 2012, notes: 'As DJ Funeral', playlistId: 'PLnD5HfA5-m75dlIlgsnBRbI315w4H2LSQ'},
    {cover: hymn, album: 'Hymn', label: 'Mixpak', year: 2011, playlistId: 'PLnD5HfA5-m74b9DJlKdwQxgP33vJ7sL9n'},
    {cover: lastBreath, album: 'Last Breath', label: 'Body High', year: 2011, notes: 'As DJ Funeral', playlistId: 'PLnD5HfA5-m75qsWw_xka8nHNE5-sj2-2X'},
    {cover: unknownToTheUnknown, album: 'B Leave', label: 'UTTU', year: 2011, playlistId: 'PLnD5HfA5-m74J1tyJrKwp_KX2QTzWvadB'},
    {cover: duttyArtzWhiteLabel, album: 'W/L', label: 'Dutty Artz', year: 2011, playlistId: 'PLnD5HfA5-m77A8pBaD_NjpM6KdqB06vcb'},
    {cover: pulso, album: 'Anaconda (Tribal Guarachero Mix) / Pulso', label: 'SSSSS', year: 2010, playlistId: 'PLnD5HfA5-m777DjK9n21LO2Jp8K-r2a46'},
    {cover: throwback, album: 'Throwback', label: 'Palms Out Sounds', year: 2010, playlistId: 'PLnD5HfA5-m77DNgATe8nYnDk5ca4J5sKl'},
    {cover: trollsta, album: 'Trollsta', label: 'Palms Out Sounds', year: 2009, playlistId: 'PLnD5HfA5-m75PlouypPncGaXHbNcKOyll'},
  ];

export default function Works({setPlaylistId}) {
    return <CoverFlow label="solo" releases={releases} setPlaylistId={setPlaylistId} />
}