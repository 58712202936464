import React, {useState, useRef} from 'react';

export default function DownloadManager({onClose}) {
    const [keyPart1, setKeyPart1] = useState("");
    const [keyPart2, setKeyPart2] = useState("");
    const [keyPart3, setKeyPart3] = useState("");
    const [keyPart4, setKeyPart4] = useState("");
    const [shake, setShake] = useState(false);

    const input1Ref = useRef();
    const input2Ref = useRef();
    const input3Ref = useRef();
    const input4Ref = useRef();

    const handleInputChange = (e, setter, nextInputRef) => {
        let value = e.target.value;
        value = value.replace(/\s/g, '');
        setter(value);
        if (value.length === 4) {
            if (nextInputRef) {
                nextInputRef.current.focus();
            } else {
                // Check the code to see if it's valid.
                // Call the API to check the code. If valid we will show a download link for a random track.
                // It won't be one that we've already shared with them (backend will keep track of that).
                // A download for a specific track will only be available a certain number of times for all users.
                // We'll have 60 tracks available for download. Each one will have 5 downloads available.

                // Let's play a game. I have a war chest full of unreleased white label edits and remixes that I'd like to share.
                // I considered flooding the world with them, but that would dilute whatever potency they have left. 
                // I considered selling them to an elite group of wealthy DJs, but that would limit this opportunity to people with the means to participate.
                // I considered creating a physical vessel for them, but industrial design is expensive and you probably have enough e-waste already.
                // Cutting an actual dubplate costs about $75 US last time I checked and not everyone has the money (or gear) for that.
                // And the technology to make an mp3 gradually degrade in bitrate with each play doesn't exist yet.
                // So, we're doing this instead...
                // There are a limited number of single use keys that can be redeemed for a randomly issued "virtual" dubplate.
                // The track you get (and you will only get one) can only be downloaded a maximum number of times. 
                // I won't reveal the actual number of redemptions, but it is very low.
                // After that, the track will become unavailable. Possibly, forever.
                // What you do with it is up to you. You can play it in your sets, listen to it in the shower, or share it on SoulSeek (but maybe try not to do that beacuse it defeats the purpose of this).
                // I can't guarantee that I won't give the tracks away one day. But I can guarantee that this day won't come for a very long time.
                // Until then, enjoy holding something special that only a handful of others possess. A lot of these are bootlegs so no purchase necessary for participation.
                // To get an activation key leave a comment and I will message you at some point. If I don't message you back withing 7 days then assume there are no more keys available.
                // If I do message you then tap the SD card on my website and enter the key I sent you to experience The Redemption.

                // For now, it's always invalid
                setKeyPart1("");
                setKeyPart2("");
                setKeyPart3("");
                setKeyPart4("");
                setShake(true);
                input1Ref.current.focus();
                setTimeout(() => setShake(false), 300);
            }
        } 
    }

    const handlePaste = (e) => {
        e.preventDefault();
        const pastedData = e.clipboardData.getData('text');
        const parts = pastedData.split('-');
        if (parts.length === 4) {
            setKeyPart1(parts[0]);
            setKeyPart2(parts[1]);
            setKeyPart3(parts[2]);
            setKeyPart4(parts[3]);
            input4Ref.current.focus();
        }
    }

    return (
        <>
            <div className="backdrop" onClick={onClose} />
            <div className={`download-manager ${shake ? 'shake' : ''}`}>
                <div className="close-button" onClick={onClose}><strong>x</strong></div>
                <div />
                <div className="label">
                    <label><strong>Activation Key:</strong></label>
                </div>
                <div>
                    <input 
                        ref={input1Ref} 
                        className="activation-key-input" 
                        maxLength={4} 
                        pattern="[A-Z0-9]{4}" 
                        value={keyPart1} 
                        onChange={(e) => handleInputChange(e, setKeyPart1, input2Ref)} 
                        onPaste={handlePaste}
                    />
                    <span className="hyphen">-</span>
                    <input ref={input2Ref} className="activation-key-input" maxLength={4} pattern="[A-Z0-9]{4}" value={keyPart2} onChange={(e) => handleInputChange(e, setKeyPart2, input3Ref)} />
                    <span className="hyphen">-</span>
                    <input ref={input3Ref} className="activation-key-input" maxLength={4} pattern="[A-Z0-9]{4}" value={keyPart3} onChange={(e) => handleInputChange(e, setKeyPart3, input4Ref)} />
                    <span className="hyphen">-</span>
                    <input ref={input4Ref} className="activation-key-input" maxLength={4} pattern="[A-Z0-9]{4}" value={keyPart4} onChange={(e) => handleInputChange(e, setKeyPart4)} />
                </div>
            </div>
        </>
    );
}
