import React, {useState} from 'react';
import CoverFlow from './CoverFlow.tsx';
import AccordianRowContainer from './AccordianRowContainer.tsx';

const divStyle = {
  fontSize: "10px",
  color: "#cccccc",
  lineBreak: "anywhere",
  wordBreak: "normal",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  fontFamily:
    "Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif",
  fontWeight: 100,
};

const textStyle = {color: "#cccccc", textDecoration: "none" }

const frameStyle = {
  paddingBottom: '20px'
};

export default function Works(props) {
  const [isOpen, setIsOpen] = useState(true);
    return (
      <AccordianRowContainer
        label="mixtapes"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        collapseContent
      >
        <div className="mixtape-container">
          <iframe
            width="100%"
            height="166"
            scrolling="no"
            frameBorder="no"
            allow="autoplay"
            src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/269000469&color=%238d04d6&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
            style={frameStyle}
          ></iframe>
          <div style={divStyle}>
            <a
              href="https://soundcloud.com/dubbeldutch"
              title="dubbel dutch"
              target="_blank"
              style={textStyle}
            >
              dubbel dutch
            </a>{" "}
            ·{" "}
            <a
              href="https://soundcloud.com/dubbeldutch/rare-earth-tones"
              title="RARE EARTH TONES"
              target="_blank"
              style={textStyle}
            >
              RARE EARTH TONES
            </a>
          </div>
          <iframe
            width="100%"
            height="166"
            scrolling="no"
            frameBorder="no"
            allow="autoplay"
            src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/20367827&color=%238d04d6&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
            style={frameStyle}
          />
          <div style={divStyle}>
            <a
              href="https://soundcloud.com/dubbeldutch"
              title="dubbel dutch"
              target="_blank"
              style={textStyle}
            >
              dubbel dutch
            </a>{" "}
            ·{" "}
            <a
              href="https://soundcloud.com/dubbeldutch/dubbel-dutch-slow-club-mix"
              title="DUBBEL DUTCH - SLOW CLUB MIX"
              target="_blank"
              style={textStyle}
            >
              DUBBEL DUTCH - SLOW CLUB MIX
            </a>
          </div>
          <iframe
            width="100%"
            height="166"
            scrolling="no"
            frameBorder="no"
            allow="autoplay"
            src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/116495164&color=%238d04d6&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
            style={frameStyle}
          ></iframe>
          <div style={divStyle}>
            <a
              href="https://soundcloud.com/dubbeldutch"
              title="dubbel dutch"
              target="_blank"
              style={textStyle}
            >
              dubbel dutch
            </a>{" "}
            ·{" "}
            <a
              href="https://soundcloud.com/dubbeldutch/cloud-club-mix"
              title="DUBBEL DUTCH - CLOUD CLUB MIX"
              target="_blank"
              style={textStyle}
            >
              DUBBEL DUTCH - CLOUD CLUB MIX
            </a>
          </div>
        </div>
      </AccordianRowContainer>
    );
}